<template>
  <div>
    <a-card :border="false">
      <div class="query">
        <a-form-model :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }">
          <a-row :gutter="4">
<!--            <a-col :xxl="6" :xl="6" :md="8" :sm="12">
              <a-form-model-item label="大区事务所">
                <a-cascader
                  :options="dealerList"
                  v-model="queryParam.regionalOffice"
                  change-on-select
                  placeholder="请选择大区/事务所"
                  @change="onChange"
                  :field-names="{ label: 'title', value: 'id', children: 'children' }"
                />
              </a-form-model-item>
            </a-col>-->
            <a-col :xxl="6" :xl="6" :md="8" :sm="12">
              <a-form-model-item label="经销商名称">
                <a-input v-model.trim="queryParam.dealerName" allowClear placeholder="经销商名称" :max-length="50"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :xxl="6" :xl="6" :md="8" :sm="12">
              <a-form-model-item label="订单号">
                <a-input v-model.trim="queryParam.orderCode" allowClear placeholder="订单号" :max-length="50"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :xxl="6" :xl="6" :md="8" :sm="12">
              <a-form-model-item label="发货单号">
                <a-input v-model.trim="queryParam.shipCode" allowClear placeholder="发货单号" :max-length="50"></a-input>
              </a-form-model-item>
            </a-col>

            <a-col :xxl="6" :xl="6" :md="8" :sm="12">
              <a-form-model-item label="收货人">
                <a-input v-model.trim="queryParam.consignee" allowClear placeholder="收货人" :max-length="50"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :xxl="6" :xl="6" :md="8" :sm="12">
              <a-form-model-item label="所在省市区">
                <PCD
                  ref="pcd"
                  placeholder="所在省市区"
                  :province.sync="queryParam.provinceName"
                  :provinceId.sync="queryParam.provinceId"
                  :city.sync="queryParam.cityName"
                  :cityId.sync="queryParam.cityId"
                  :district.sync="queryParam.area"
                  :districtId.sync="queryParam.areaId"
                ></PCD>
              </a-form-model-item>
            </a-col>
            <a-col :xxl="6" :xl="6" :md="8" :sm="12">
              <a-form-model-item label="详细地址">
                <a-input v-model.trim="queryParam.address" allowClear placeholder="详细地址" :max-length="50"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :xxl="6" :xl="6" :md="8" :sm="12">
              <a-form-model-item label="发货时间">
                <DateRange :startTime.sync="queryParam.startTime" :endTime.sync="queryParam.endTime"></DateRange>
              </a-form-model-item>
            </a-col>
            <a-col :xxl="6" :xl="6" :md="8" :sm="12">
              <a-form-model-item :labelCol="{ span: 0 }" :wrapperCol="{ span: 22, offset: 1 }">
                <a-button @click="toSearch(0)" type="primary" icon="search">查询</a-button>
                <a-button @click="reset()" type="default" icon="sync">重置</a-button>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
      <div>
<!--    0711屏蔽    -->
<!--        <a-button @click="toHandler(null, 'info')" type="primary" v-if="isInvoicing && selectedRowKeys.length > 0">开票</a-button>-->
        <a-button type="primary" v-if="selectedRowKeys.length > 0 && isEditInvoice" @click="toHandlerInfo" icon="form">修改发票号</a-button>
        <a-upload
          name="file"
          :action="uploadUrl"
          :headers="headers"
          :showUploadList="false"
          @change="uploadChange"
        >
          <a-button v-if="isInvoiceImport" icon="upload" type="default">导入</a-button>
        </a-upload>
        <!--         <a-button @click="toHandler(null, 'ship')" type="primary" v-if="isLogistics">完善物流信息</a-button> -->
      </div>
      <div class="table">
        <a-table
          :loading="tableLoading"
          :data-source="tableData"
          type="checkbox"
          :row-selection="rowSelection"
          :rowKey="(record) => record.id"
          bordered
          :customRow="changeTableRow"
          :pagination="page"
          @change="
            (p) => {
              page = p
              toSearch(2)
            }
          "
        >
          <a-table-column title="经销商名称" data-index="dealerName" :width="210" align="left" >
          </a-table-column>
          <a-table-column title="发货单号" data-index="shipCode" :width="210" :ellipsis="true">
            <template slot-scope="text">
              <a-tooltip
              >
                <template slot="title"> {{ text }}</template>
                {{ text }}
              </a-tooltip>
            </template>
          </a-table-column>
          <!-- <a-table-column title="经销商名称" data-index="dealerName" :width="210"> </a-table-column> -->
          <a-table-column title="订单号" data-index="orderCode" :width="210"></a-table-column>
          <a-table-column title="发票号" data-index="invoiceNo" :width="210">
            <template slot-scope="text, row">
              <div v-for="(item, index) in row.invoiceNo" :key="index">
                <a @click="checkInvoiceInfo(item)"> {{item}} </a>
              </div>
<!--              {{ text != null ? text : '/' }}-->
            </template>
          </a-table-column>
          <a-table-column title="实际支付价(元)" data-index="actualPrice" :width="210">
            <template slot-scope="text">
              <span v-if="!text">--</span>
              <span v-else>{{ text | formatMoney }}</span>
            </template>
          </a-table-column>
          <a-table-column title="地址" data-index="street" :width="450" :ellipsis="true" align="left">
            <template slot-scope="text, row">
              {{ row.consignee }}，{{ row.phone }}
              <br />
              {{ row.provinceName }}{{ row.cityName }}{{ row.areaName }}{{ row.address }}
            </template>
          </a-table-column>
          <a-table-column title="发货时间" data-index="createTime" :width="210" :ellipsis="true" align="left">
          </a-table-column>
        </a-table>
      </div>
      <OrderInfoModal ref="orderInfoModal" @change="toSearch(1)"></OrderInfoModal>
      <InvoiceInfoModal ref="InvoiceInfoModal"></InvoiceInfoModal>
      <a-modal v-model="visible" title="修改发票号" @ok="handleOk"  @cancel="handleCance" :maskClosable="false" width="40%" :confirm-loading="confirmLoading">
        <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 15 }">
          <a-form-model-item label="发票号">
            <a-input v-model="invoiceCode" placeholder="请输入发票号"></a-input>
            <span>填写多个时请用,隔开</span>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </a-card>
  </div>
</template>
<script>
  import {selectExpressCodeByInvoiceCode} from "@/views/InvoiceAdmin/api/FinanceReceiptApi";

  const qs = require('qs')
  import QRCode from 'qrcodejs2'
  import {baseURL} from '@/utils/request'
  import OrderInfoModal from './components/OpenInvoice.vue'
  import {checkPermission} from '@/utils/permissions'
  import InvoiceInfoModal from '@/views/InvoiceAdmin/components/InvoiceInfoModal.vue'

  export default {
    data() {
      return {
        putloading: false,
        putVisible: false,
        queryParam: {},
        value: '',
        resetQueryParam: null,
        tableData: [],
        tableLoading: false, //表格loading
        dealerList: [],
        selectedRowKeys: [],
        selectedRows: [],
        loading: false,
        page: {
          current: 1,
          pageSize: 10,
          total: 0,
          showSizeChanger: true,
          showTotal: () => `共${this.page.total}条`,
        },
        visible: false,
        title: '物流信息',
        form: {},
        isLoading: false,
        tableData2: [],
        row: null,
        shipIdList: [],
        invoiceCode:'',
        shipCode:'',
        confirmLoading:false,
        printOrderInfoList: {},
        dealerSearchDataList: [],
        dealerIdSearch: undefined,
        isInvoicing:checkPermission('invoiceApply:Invoicing'),
        isPrint: checkPermission('ordersend:shippingOrder:printing'),
        isOrderDetail: checkPermission('ordersend:shippingOrder:exportOrderDetailed'),
        isExport: checkPermission('ordersend:shippingOrder:exportOrder'),
        isLogistics: checkPermission('ordersend:shippingOrder:logistics'),
        openInvoice: checkPermission('order:invoice:open'),
        isInvoiceImport: checkPermission("order:invoice:import"),
        isEditInvoice:checkPermission('invoiceApply:editInvoice'),
        //上传的数据
        uploadData: [],
        //文件数据
        fileList: [],
        //上传文件地址
        uploadUrl: baseURL + '/api/order/invoice/orderShipInfo/excelReader',
        //上传文件请求头
        headers: {
          'Authorization-Admin': this.$store.getters.token,
        },
      }
    },
    components: {
      OrderInfoModal,
      InvoiceInfoModal,
    },
    computed: {
      rowSelection() {
        const _this = this
        return {
          fixed: true,
          type: 'checkbox', //"radio"
          selectedRowKeys: this.selectedRowKeys,
          onChange: (selectedRowKeys, selectedRows) => {
            _this.selectedRowKeys = selectedRowKeys
            _this.selectedRows = selectedRows
            console.log(_this.selectedRowKeys)
          },
        }
      },
      getRowId() {
        return {id: this.selectedRowKeys[0]}
      },
    },
    methods: {
      // 审核弹框
      toHandlerInfo() {
        const _this=this;
        if (_this.selectedRows.length === 0) {
          _this.$notification.info({
            message: '请选择一条记录',
          })
          return
        }
        _this.invoiceCode = _this.selectedRows[0].invoiceNo.toString()
        _this.visible = true;
      },
      handleOk(){
        this.confirmLoading = true
        let params = {
          invoiceCode: this.invoiceCode.replace('，', ','),
          shipCode: this.selectedRows[0].shipCode
        }
        this.axios.post('/api/order/invoice/orderShipInfo/updateInvoiceCode', params).then(res => {
          if (res.code == 200) {
            this.$message.success(res.message)
            this.visible = false
            this.toSearch(0)
          }
        }).finally(() => {
          this.confirmLoading = false
        })
      },
      handleCance(){
        this.confirmLoading = false
        this.visible = false
        this.invoiceCode = ''
        this.shipCode = ''
      },
      // uploadChange(info) {
      //   if (info.file.status !== 'uploading') {
      //     console.log(info.file, info.fileList)
      //   }
      // },
      //导入
      uploadChange (info) {
        this.tableLoading = true
        setTimeout(()=>{
        if(info.file.response){
        let hint = info.file.response
        if(hint.code === 200) {
          this.tableLoading = false
          this.$message.success(hint.message)
          this.toSearch(0)
        }else if(hint.code === 500) {
          this.tableLoading = false
          this.$message.error(hint.message)
        }
        if (info.file.status !== 'uploading') {
          console.log(info.file, info.fileList)
        }
        }
        })

      },
      onChangeShipDate(date, dateString) {
        console.log(date, dateString)
        this.queryParam.startTime = dateString[0]
        this.queryParam.endTime = dateString[1]
      },
      changeOpenInvoiceInfo(value) {
        console.log(value)
        this.dealerIdSearch = value
        this.queryParam.dealerId = value
        this.toSearch(0)
      },
      toSearch(type) {
        this.tableLoading = true
        if(type == 1){
          this.resetQueryParam
            ? (this.queryParam = Object.assign({}, this.resetQueryParam))
            : (this.resetQueryParam = Object.assign({}, this.queryParam))
        }
        this.queryParam.pageNumber = type == 2 ? this.page.current : (this.page.current = 1)
        this.queryParam.pageSize = this.page.pageSize
        const postData = Object.assign({}, this.queryParam, this.page)
        console.log(postData)
        console.log(this.queryParam.dealerId)
        this.axios
          .get(`/api/order/invoice/orderShipInfo/administrationList?${qs.stringify(postData)}`)
          .then((res) => {
            let list = []
            for(let i=0;i<res.body.records.length;i++){
              if(!res.body.records[i].invoiceNo){
                res.body.records[i].invoiceNo=i
              }else {
                let code = res.body.records[i].invoiceNo.split(',')
                for(let x=0; x<code.length; x++){
                  list.push(code[x])
                }
              }
              res.body.records[i].invoiceNo = list
              list = []
            }
            this.tableData = res.body.records
            this.page.total = res.body.total
            this.selectedRowKeys = []
            this.selectedRows = []
            this.tableLoading = false
          })
          .catch((err) => {
            this.tableLoading = false
          }).finally(() => (this.tableLoading = false))
      },
      /**
       * 重置
       */
      reset() {
        this.queryParam = {}
        this.page.current = 1;
        this.page.pageSize = 10;
        this.$nextTick( () => {
          this.$refs.pcd.setPCD()
        })
        this.toSearch(0)
      },
      toCancel() {
        this.$refs.form && this.$refs.form.resetFields()
        this.visible = false
      },
      toSearch2() {
        this.tableLoading = true
        let postData = {
          shipCode: this.selectedRows[0].shipCode,
          orderCode: this.selectedRows[0].orderCode,
          logisticsCode: this.selectedRows[0].deliveryNum,
        }
        this.axios
          .get(`/api/order/system/orderShipLogistics/administrationList?${qs.stringify(postData)}`)
          .then((res) => {
            this.tableData2 = res.body.logisticsList
            this.form.imgUrl = res.body.imgUrl
            this.selectedRowKeys = []
            this.selectedRows = []
          }).finally(() => (this.tableLoading = false))
      },
      // 打印
      onPrint(item) {
        if (this.selectedRows.length === 0) {
          this.$notification.info({
            message: '请选择一条记录',
          })
          return
        }
        this.axios
          .get(`/api/order/system/orderInfo/selectByCode?orderCode=${this.selectedRows[0].orderCode}`)
          .then((res) => {
            this.putloading = false
            this.putVisible = true
            this.printOrderInfoList = res.body
            this.$nextTick(function () {
              document.getElementById('qrcode').innerHTML = ''
              let qrcode = new QRCode('qrcode', {
                width: 60,
                height: 60,
                text: res.body.orderCode,
                colorDark: '#109dff',
                colorLight: '#d9d9d9',
              })
            })
          })
          .catch((error) => {
            this.putloading = false
          })
      },
      /**
       * 增、查、删（存在type）
       */
      toHandler(row, name) {
        this.shipIdList = this.selectedRows.map((item) => {
          return item.id
        })

        let arr = []
        for (var i = 0; i < this.selectedRows.length; i++) {
          if (arr.indexOf(this.selectedRows[i].dealerId) === -1) {
            arr.push(this.selectedRows[i].dealerId)
          }
        }
        if (arr.length > 1) {
          return this.$message.warning('请选择同一经销商')
        }

        if (this.selectedRows.length <= 0) {
          return this.$message.warning('请选择一条数据')
        }
        switch (name) {
          case 'info':
            this.$refs.orderInfoModal.showInfo(this.shipIdList, this.selectedRows[0].dealerId)
            // this.selectedRows[0].shipCode,
            // 'shipOrder',
            // this.selectedRowKeys
            // this.$refs.orderInfoModal.showInfo(row.orderCode,row.shipCode)
            break
          case 'ship':
            this.visible = true
            this.form = {}
            this.row = row
            this.toSearch2()
            break
          case 'info':
            this.visible = true
            this.form = {}
            this.row = row
            this.toSearch2()
            break
          case 'delShip':
            this.$confirm({
              title: '确定删除该条记录',
              onOk: () => {
                this.axios
                  .post(`/api/order/system/orderShipLogistics/del/${row.id}`)
                  .then((res) => {
                    this.$message.success(res.message)
                    this.form = {}
                    this.toSearch2(0)
                  })
                  .catch((err) => {
                  })
              },
            })
            break
        }
      },
      /**
       * 点击行选中
       */
      changeTableRow(record) {
        return {
          on: {
            click: (e) => {
              if (this.selectedRowKeys[0] === record.id) {
                this.selectedRowKeys = []
                this.selectedRows = []
              } else {
                this.selectedRowKeys = [record.id]
                this.selectedRows = [record]
              }
            },
          },
        }
      },
      onChange(val) {
        this.searchData.bigAreaId = val[0]
        this.searchData.firmId = val[1]
      },
      //打开上游单据
      checkInvoiceInfo(row) {
        selectExpressCodeByInvoiceCode(row).then(res => {
          this.$refs.InvoiceInfoModal.setRowData(res.body, 'check')
        })
      },
    },
    created() {
      console.log("========");
      this.axios.post('/api/firm/mhiac/mhiacFirm/getTree').then((res) => {
        // 获取大区事务所经销商数据
        this.dealerList = res.body;
        this.toSearch(0);
      })
    },
  }
</script>


<style lang="less" scoped>
</style>